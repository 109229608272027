<template>
  <div class="landing">
    <helmet>
      <title>ChannelQuiz — Сервис для создания интерактивных постов</title>
    </helmet>
    <header
      class="landing-header"
      :style="{backgroundColor: `rgba(102,126,234,${headerOpacity})`, boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${headerOpacity*0.1}), 0 2px 4px -1px rgba(0, 0, 0, ${headerOpacity*0.06})`}">

      <a class="landing-header__logo" href="#" @click.prevent="scrollToTop"/>
      <div class="spacer"/>
      <div class="flex flex-row items-center overflow-x-auto">
        <router-link
          class="landing-header__link landing-header__link_filled mr-2"
          to="/dashboard"
          v-if="$store.state.auth.userIdFetched && $store.state.auth.userId">
          Dashboard
        </router-link>
        <LandingHeaderLink :active-link="activeLink" name="overview">Обзор</LandingHeaderLink>
        <LandingHeaderLink :active-link="activeLink" name="features">Преимущества</LandingHeaderLink>
        <LandingHeaderLink :active-link="activeLink" name="feedback">Отзывы</LandingHeaderLink>
        <LandingHeaderLink :active-link="activeLink" name="pricing">
          Цены
          <span v-if="promoCode" class="landing-header__link-badge">
            promo
          </span>
        </LandingHeaderLink>
        <LandingHeaderLink :active-link="activeLink" name="contacts">Контакты</LandingHeaderLink>
        <router-link
          class="landing-header__link landing-header__link_filled ml-2"
          to="/login"
          v-if="!$store.state.auth.userIdFetched || !$store.state.auth.userId">
          Log in
        </router-link>
      </div>
    </header>
    <div class="landing__block landing__block_full-height front-block">
      <div class="front-block__content">
        <img class="landing__logo mr-4" src="@/assets/logo/ChannelQuiz_logo.png"/>
        <div class="front-block__text-content">
          <h1 class="front-block__name">ChannelQuiz</h1>
          <span class="front-block__description">Сервис для создания интерактивных постов</span>
        </div>
      </div>
    </div>
    <h4 class="handwritten text-4xl font-normal py-8 text-center">
      Интерактивные посты — путь к повышению вовлеченности аудитории.
    </h4>
    <div class="landing__block overview-block">
      <div class="landing__anchor" id="overview" ref="overviewAnchor"/>
      <h1 class="landing__block-header">Как это работает?</h1>
      <div class="overview-block__content" ref="overviewBlockContent">
        <div class="overview-block__items" ref="steps">
          <div class="overview-block__item" :class="getHighlightClass(1)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Создавай посты</h2>
            </div>
          </div>
          <div class="overview-block__item" :class="getHighlightClass(2)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Прикрепляй картинки</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(3)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Добавляй кнопки с вариантами ответов</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(4)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Собирай статистику</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(5)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Добавляй комментарии</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(6)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Добавляй ссылки</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(7)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Добавляй реакции</h2>
            </div>

          </div>
          <div class="overview-block__item" :class="getHighlightClass(8)">
            <div class="overview-block__item-content">
              <h2 class="overview-block__item-header">Добавляй статьи</h2>
            </div>

          </div>
        </div>
        <transition name="fade-200">
          <div
            class="overview-block__fixed-part"
            :class="{'overview-block__fixed-part_fixed': overviewBlockFixedPartFixed}"
            v-show="mockupVisible">
            <div class="overview-block__fixed-part-content">
              <div class="mockup__magnifier-wrapper" :class="magnifiedClass">
                <div class="mockup__magnifier">
                  <div class="mockup">
                    <div class="mockup__content">
                      <img class="mockup__statusbar" src="@/assets/landing/statusbar.png">
                      <img class="mockup__navbuttons" src="@/assets/landing/navbuttons.png">
                      <img class="mockup__top" src="@/assets/landing/top.png"/>
                      <img class="mockup__bottom" src="@/assets/landing/bottom.png"/>
                      <div class="mockup__pointer" ref="pointer"/>
                      <transition-group name="fade-200" :duration="200">
                        <img class="mockup__post" key="1" v-if="step===1" src="@/assets/landing/step1.png"/>

                        <img class="mockup__post" key="2" v-if="step===2" src="@/assets/landing/step2.png"/>

                        <img class="mockup__post" key="3" v-if="step===3" src="@/assets/landing/step3.png"/>
                        <div class="mockup__pointer mockup__step3-pointer" key="3-pointer" v-if="step===3"/>
                        <div class="mockup__popup mockup__step3-popup" key="3-popup" v-if="step===3">
                          <img src="@/assets/landing/step3_popup.png" class="mockup__popup-content">
                        </div>

                        <img class="mockup__post" key="4" v-if="step===4" src="@/assets/landing/step4.png"/>
                        <div class="mockup__pointer mockup__step4-pointer" key="4-pointer" v-if="step===4"/>
                        <div class="mockup__popup mockup__step4-popup" key="4-popup" v-if="step===4">
                          <img src="@/assets/landing/step4_popup.png" class="mockup__popup-content">
                        </div>

                        <img class="mockup__post" key="5" v-if="step===5" src="@/assets/landing/step5.png"/>

                        <img class="mockup__post" key="6" v-if="step===6" src="@/assets/landing/step6.png"/>

                        <img class="mockup__post" key="7" v-if="step===7" src="@/assets/landing/step7.png"/>

                        <img class="mockup__post" key="8" v-if="step===8" src="@/assets/landing/step8.png"/>
                        <img class="mockup__webpage_header mockup__step8-webpage-header" key="8-webpage-header"
                             v-if="step===8"
                             src="@/assets/landing/step8_webpage_header.png"/>
                        <img class="mockup__webpage mockup__step8-webpage" key="8-article" v-if="step===8"
                             src="@/assets/landing/step8_article.png"/>
                        <div class="mockup__pointer mockup__step8-pointer" key="8-pointer" v-if="step===8"/>
                      </transition-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="landing__block features-block">
      <div class="landing__anchor" id="features" ref="featuresAnchor"/>
      <div class="features-block__content">
        <h1 class="landing__block-header">Почему ChannelQuiz?</h1>
        <div class="features-block__features">
          <div class="features-block__feature">
            <img src="../assets/landing/icons/website.png"/>
            <h2 class="features-block__feature-header">
              Удобный сайт
            </h2>
            <p class="features-block__feature-description">
              Создавай посты в браузере. <br/>
              С любого устройства.
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/scheduled.png"/>
            <h2 class="features-block__feature-header">
              Отложенные публикации
            </h2>
            <p class="features-block__feature-description">
              Откладывай посты на любое время с точностью до минуты.
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/editor.png"/>
            <h2 class="features-block__feature-header">
              Редактирование без ограничений
            </h2>
            <p class="features-block__feature-description">
              Редактируй посты в любое время. Исправить опечатки? Заменить картинку?
              Перенести отложенный пост? Легко!
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/stats.png"/>
            <h2 class="features-block__feature-header">
              Правильный подсчёт статистики
            </h2>
            <p class="features-block__feature-description">
              Мы записываем первый ответ подписчиков, а не последний, поэтому статистика поста всегда показывает уровень
              реальных знаний.
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/comments.png"/>
            <h2 class="features-block__feature-header">
              Поддержка комментариев
            </h2>
            <p class="features-block__feature-description">
              Интеграция с comments.bot
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/articles.png"/>
            <h2 class="features-block__feature-header">
              Встроенный редактор статей
            </h2>
            <p class="features-block__feature-description">
              Создавай статьи прямо на сайте через удобный редактор. Поддерживаются заголовки, списки, картинки, таблицы
              и не только.
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/i18n.png"/>
            <h2 class="features-block__feature-header">
              Локализация
            </h2>
            <p class="features-block__feature-description">
              Поддерживаются русский, украинский, английский и узбекский языки.
            </p>
          </div>
          <div class="features-block__feature">
            <img src="../assets/landing/icons/rich-editing.png"/>
            <h2 class="features-block__feature-header">
              Полнофункциональный редактор
            </h2>
            <p class="features-block__feature-description">
              Создавать посты через сайт — как через Telegram, но удобнее! Поддерживаются все способы форматирования текста, а также детальная настройка inline-кнопок.
            </p>
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/landing/laptop_phone_mockup.png" class="pt-32 pb-8 mx-auto px-8" height="801" width="1408"/>
    <div class="landing__block feedback-block">
      <div class="landing__anchor" id="feedback" ref="feedbackAnchor"/>
      <div class="feedback-block__feedback-container-wrapper">
        <!--      <h1 class="landing__block-header">Отзывы</h1>-->
        <h3 class="font-medium text-center text-5xl font-bold handwritten">Нам доверяют</h3>
        <p class="text-center">
          Наши клиенты — каналы с различными тематиками и количеством подписчиков.
        </p>
        <div class="feedback-block__channels">
          <a
            v-for="channel in channels"
            class="feedback-block__channel"
            :href="channel.link"
            target="_blank"
            :title="channel.name">
            <img
              alt="avatar"
              class="feedback-block__channel-image"
              :src="require(`../assets/landing/avatars/${channel.avatarName}`)"/>
          </a>
        </div>
        <h1 class="font-medium text-center text-5xl pb-4 font-bold handwritten">Отзывы о нас</h1>
        <div class="overflow-hidden flex flex-row justify-start -mx-4" ref="widthMeter">
          <div
            class="feedback-block__feedback-container"
            :style="feedbackContainerStyle"
            :class="feedbackContainerClass">
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Огромное спасибо за бота!
                Полностью закрыл потребности в
                моем канале. Работает «как часы»! На любые
                вопросы ребята отвечают практически мгновенно. Рекомендую на все 100%.
                Так держать!!!</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/green_way_com_ua.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Алексей Бугарь</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/green_way_com_ua">@green_way_com_ua</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Сервис
                классный, ребята молодцы, каждый баг моментально фиксят,
                поддерживают работу сайта👍</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/englishworld.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Михаил</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/eng_world">EnglishWorld</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Пользуюсь
                @ChannelQuizBot уже около 4 месяцев, сервис имеет несложную функциональность,
                всё это время переодически обновляется.
                Все непонятные моменты доступно объясняют.
                В целом, удобный бот с большим количеством возможностей.</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/brain-service.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Ralph Lauren</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/joinchat/AAAAAEu4PFmvpu_kEX8f6A">Brain Service</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Сервис
                хороший однозначно, удобно, как средство развлечения подписчиков в нашей тематике Туризма самое то.</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/aliard_ta.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Ilya Lazarev</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/aliard_ta">@aliard_ta</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Доступный и интуитивный бот, отличная система обратной связи,
                на страже 24/7<br/>
                Частые обновления по оптимизации радуют глаз, рекомендую)</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/где-правда.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Саша Савицкий</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/joinchat/AAAAAEuqVvSa0a6xiabZNg">ГДЕ ПРАВДА?</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="feedback-block__feedback" :style="feedbackStyle">
              <p class="feedback-block__feedback-content">Бот работает отлично,
                багов нету. Сразу постит на канал и плюс можно подключить несколько каналов,
                можно видеть статистику и еще удобный редактор возможностей</p>
              <div class="flex flex-row justify-center">
                <img
                  class="feedback-block__feedback-avatar"
                  src="../assets/landing/avatars/ofilms.jpg"
                  alt="avatar"/>
                <div class="flex flex-col">
                  <span class="feedback-block__feedback-name">Rustam Olimov</span>
                  <span class="feedback-block__feedback-description">
                    из <a target="_blank" href="https://tttttt.me/Ofilms">@Ofilms</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="feedback-block__feedback-dots-wrapper">
          <button
            v-for="i in feedbackCount" :class="{'feedback-block__feedback-dots-dot_active': i-1 === feedbackIndex}"
            class="feedback-block__feedback-dots-dot"
            @click="goToFeedback(i-1)"/>
        </div>
      </div>
    </div>
    <div class="landing__block pricing-block">
      <div class="landing__anchor" id="pricing" ref="pricingAnchor"/>
      <h1 class="landing__block-header mb-4">Цены</h1>
      <div class="pricing-block__content">
        <div class="flex flex-col items-center p-12">
          <div class="pricing-block__radio">
            <label class="pricing-block__switch-item">
              <input type="radio" v-model="subscriptionLength" :value="1" class="hidden"/>
              <span class="pricing-block__switch-item-content">1 месяц</span>
            </label>
            <label class="pricing-block__switch-item">
              <input type="radio" v-model="subscriptionLength" :value="6" class="hidden"/>
              <span class="pricing-block__switch-item-content">6 месяцев</span>
            </label>
            <label class="pricing-block__switch-item">
              <input type="radio" v-model="subscriptionLength" :value="12" class="hidden"/>
              <span class="pricing-block__switch-item-content">12 месяцев</span>
            </label>
          </div>
          <div class="pt-20 text-center flex flex-col">
            <span v-if="promoCode" class="text-2xl font-bold handwritten">
              5 дней бесплатно по промокоду <span class="text-indigo-600">{{promoCode}}</span>, затем
            </span>
            <span class="text-5xl font-bold handwritten">{{price}}₽/месяц</span>
          </div>
          <span class="pt-1 text-2xl font-bold handwritten">
            <template v-if="subscriptionLength === 1">
              (оплачивается раз в месяц)
            </template>
            <template v-else>
              ({{subscriptionLength*price}}₽ каждые {{subscriptionLength}} месяцев)
            </template>
          </span>
          <a target="_blank" href="https://tttttt.me/ChannelQuizFeedbackBot" class="pricing-block__button">
            {{promoCode ? 'Попробовать' : 'Купить'}}
          </a>
        </div>
        <div class="pricing-block__benefits">
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Подключение 1 канала</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Неограниченное количество постов</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Доступ для всех администраторов канала</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Отложенный постинг</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Редактирование без ограничений
          </span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Поддержка реакций</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Поддержка комментариев</span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Подсчёт статистики
          </span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Редактор статей
          </span>
          <span class="font-sm text-gray-700 my-1">
            <el-icon name="check"/>
            Внимательная поддержка
          </span>
        </div>
      </div>
    </div>
    <div class="landing__block contacts-block">
      <div class="landing__anchor" id="contacts" ref="contactsAnchor"/>
      <div class="contacts-block__links">
        <a class="contacts-block__link" href="https://tttttt.me/ChannelQuiz">Канал с новостями</a>
        <a class="contacts-block__link" href="https://tttttt.me/ChannelQuizBot?start=landing">Основной бот</a>
        <a class="contacts-block__link" href="https://tttttt.me/ChannelQuizFeedbackBot">Обратная связь</a>
      </div>
      <div class="p-2 text-sm">
        © 2019-2020 ChannelQuiz
      </div>
      <div class="p-2 text-xs">
        <span>
          Mockup by <a target="_blank" class="text-white font-semibold underline hover:text-white"
                       href="https://freepik.com">Freepik</a>,
        </span>
        <span>
          Icons by <a target="_blank" class="text-white font-semibold underline hover:text-white"
                      href="https://icons8.ru/">Icons8</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'

  import LandingHeaderLink from '@/components/Landing/LandingHeaderLink.vue'

  import {
    enableForMixin,
  } from '@/mixins/common'

  import {
    nextTick,
  } from '@/mixins/utils'

  import store from '@/store'
  import { chain } from '@/functions/utils'
  import { apiCheckAuth } from '@/functions/api'

  export default {
    name: 'Landing',
    mixins: [
      enableForMixin,
      nextTick,
    ],
    components: {
      Helmet,

      LandingHeaderLink,
    },
    data() {
      return {
        step: 0,

        headerOpacity: 0,

        subscriptionLength: 1,

        feedbackCount: 6,
        feedbackWidth: 10,
        feedbackIndex: 0,
        feedbackTransition: false,

        activeLink: null,

        overviewBlockFixedPartFixed: false,

        channels: [
          {
            name: 'Правда VS Ложь',
            link: 'https://tttttt.me/joinchat/AAAAAFkOpzhwsm0iEDZPzw',
            avatarName: 'правда-vs-ложь-2.jpg',
          },
          {
            name: 'Умник',
            link: 'https://tttttt.me/joinchat/AAAAAE_PLcA9IGolPkQvxw',
            avatarName: 'умник.jpg',
          },
          {
            name: 'Правда vs Ложь',
            link: 'https://tttttt.me/joinchat/AAAAAFU-Pv3Maoj2RXjVMQ',
            avatarName: 'правда-vs-ложь.jpg',
          },
          {
            name: 'Правда или Ложь',
            link: 'https://tttttt.me/joinchat/AAAAAE7vIEqQUdipw4sKeQ',
            avatarName: 'правда-или-ложь.jpg',
          },
          {
            name: 'Эврика',
            link: 'https://tttttt.me/joinchat/AAAAAFTEM3KToD-mhCyy1g',
            avatarName: 'эврика.jpg',
          },
          {
            name: 'Логика',
            link: 'https://tttttt.me/joinchat/AAAAAEPGArvE_OsDapi05w',
            avatarName: 'логика.jpg',
          },
          {
            name: 'IQ | +100 к интеллекту',
            link: 'https://tttttt.me/joinchat/AAAAAFOZt8Ah_Lpa1KXaow',
            avatarName: 'iq.jpg',
          },
          {
            name: 'М О З Г О Л О М К А',
            link: 'https://tttttt.me/joinchat/AAAAAFRjjvgEySrcg0-kOg',
            avatarName: 'мозголомка.jpg',
          },
          {
            name: 'Загадки и факты',
            link: 'https://tttttt.me/joinchat/AAAAAEOQKap42qnzKAv7_w',
            avatarName: 'загадки-и-факты.jpg',
          },
          {
            name: 'Развивайся, Боб',
            link: 'https://tttttt.me/joinchat/AAAAAFKySu-l0hpLbfkgLA',
            avatarName: 'развивайся-боб.jpg',
          },
          {
            name: 'История в опросах',
            link: 'https://tttttt.me/joinchat/AAAAAE-thDt8Cd-HyI_ggQ',
            avatarName: 'история-в-опросах.jpg',
          },
          {
            name: 'География в опросах',
            link: 'https://tttttt.me/joinchat/AAAAAERdD9jzJ-cs_pCTXw',
            avatarName: 'география-в-опросах.jpg',
          },
          {
            name: 'Географ',
            link: 'https://tttttt.me/joinchat/AAAAAFT6MU0uuWrF5zV4pw',
            avatarName: 'географ.jpg',
          },
          /*{
            name: 'SL: English Every Day',
            link: 'https://tttttt.me/englishsl',
            avatarName: 'englishsl.jpg',
          },*/
          {
            name: 'Daily English',
            link: 'https://tttttt.me/daileng',
            avatarName: 'daileng.jpg',
          },
          {
            name: 'Английский в тестах',
            link: 'https://tttttt.me/supereng',
            avatarName: 'supereng.jpg',
          },
          /*{
            name: 'EnglishWorld',
            link: 'https://tttttt.me/joinchat/AAAAAFjGxdPuUFhzOPp_8A',
            avatarName: 'englishworld.jpg',
          },*/
          {
            name: 'Green Way (Тести з ПДР)',
            link: 'https://tttttt.me/green_way_com_ua',
            avatarName: 'green_way_com_ua.jpg',
          },
          {
            name: 'АвтоЛихорадка',
            link: 'https://tttttt.me/joinchat/AAAAAFZxuFkRLFpzDyPMOg',
            avatarName: 'автолихорадка.jpg',
          },
          {
            name: 'AutoMoto&Sport TV',
            link: 'https://tttttt.me/joinchat/AAAAAEX4t3sHdm2iktrU7w',
            avatarName: 'automoto.jpg',
          },
          /*{
            name: 'Good Master',
            link: 'https://tttttt.me/joinchat/AAAAAENFk8aD_K06yhItfQ',
            avatarName: 'good-master.jpg',
          },*/
          /*{
            name: 'Первый Автомобильный',
            link: 'https://tttttt.me/autofirst',
            avatarName: 'autofirst.jpg',
          },*/
          /*{
            name: 'ПДР України',
            link: 'https://tttttt.me/pdrukr',
            avatarName: 'pdrukr.jpg',
          },*/
          /*{
            name: 'ГДЕ ПРАВДА?',
            link: 'https://tttttt.me/joinchat/AAAAAEuqVvSa0a6xiabZNg',
            avatarName: 'где-правда.jpg',
          },*/
          {
            name: 'Задачи на логику Tangram',
            link: 'https://tttttt.me/tangramzadachi',
            avatarName: 'tangramzadachi.jpg',
          },
          {
            name: 'Правда vs Ложь',
            link: 'https://tttttt.me/True_vs_False',
            avatarName: 'true_vs_false.jpg',
          },
          {
            name: 'Читай!',
            link: 'https://tttttt.me/joinchat/AAAAAFalRN45-hxtRmRirQ',
            avatarName: 'читай.jpg',
          },
          /*{
            name: 'CRIMINAL BIO',
            link: 'https://tttttt.me/joinchat/AAAAAFHzXdty-Gd2TvtU9w',
            avatarName: 'criminal-bio.jpg',
          },*/
          {
            name: 'ПСИХОТЕСТЫ',
            link: 'https://tttttt.me/joinchat/AAAAAEN4ul9_IduBJHQchg',
            avatarName: 'психотесты.jpg',
          },
          /*{
            name: 'Узнай',
            link: 'https://tttttt.me/joinchat/AAAAAFkxOj7ucURtRJJ9ZQ',
            avatarName: 'узнай.jpg',
          },*/
          /*{
            name: 'Интересные факты 💥',
            link: 'https://tttttt.me/joinchat/AAAAAFe3C3CdhI_jtFyExg',
            avatarName: 'интересные-факты.jpg',
          },*/
          /*{
            name: 'Жизнь Интеллектуала',
            link: 'https://tttttt.me/joinchat/AAAAAE96IQnytXo8RzV_Ag',
            avatarName: 'жизнь-интеллектуала.jpg',
          },*/
          {
            name: 'Zakovat Quiz',
            link: 'https://tttttt.me/zakovatquiz',
            avatarName: 'zakovatquiz.jpg',
          },
          /*{
            name: 'Tarixdan qo\'llanmalar|Ilyosxõja Bekmirzayev',
            link: 'https://tttttt.me/joinchat/AAAAAFIeqSEzFVHpg2JE2A',
            avatarName: 'tarixdan.jpg',
          },*/
          {
            name: 'O\'zbekiston tarixi | BLOG',
            link: 'https://tttttt.me/joinchat/AAAAAE4WgNHvspgeRL0ZTw',
            avatarName: 'tarixi.jpg',
          },
          {
            name: 'Sport ring',
            link: 'https://tttttt.me/sport_ring',
            avatarName: 'sport_ring.jpg',
          },
          {
            name: 'Islom Quiz (Savollar)',
            link: 'https://tttttt.me/islomquiz',
            avatarName: 'islomquiz.jpg',
          },
          /*{
            name: 'BILASIZMI?',
            link: 'https://tttttt.me/joinchat/AAAAAEsteANyfWvQJigWSg',
            avatarName: 'bilasizmi.jpg',
          },*/
          /*{
            name: 'Выбирай: Правда или Ложь',
            link: 'https://tttttt.me/viberi_otvet',
            avatarName: 'viberi_otvet.jpg',
          },*/
          /*{
            name: 'Немецкий',
            link: 'https://tttttt.me/deutschonly',
            avatarName: 'deutschonly.jpg',
          },*/
          /*{
            name: 'ListEN',
            link: 'https://tttttt.me/joinchat/AAAAAEjis2pkFug46M3bSA',
            avatarName: 'listen.jpg',
          },*/
          /*{
            name: 'П҉Р҉А҉В҉Д҉А҉ 𝓿𝓼 Л҉О҉Ж҉Ь',
            link: 'https://tttttt.me/joinchat/AAAAAFWOzCY8Uhxg9ElVGA',
            avatarName: 'правда-vs-ложь-3.jpg',
          },*/
          /*{
            name: 'islam click коротко о важном',
            link: 'https://tttttt.me/islamclick',
            avatarName: 'islamclick.jpg',
          },*/
          // не очень пользуются / не очень постят
          // {
          //   name: 'Жизнь в опросах',
          //   link: 'https://tttttt.me/joinchat/AAAAAE0dWHHTUskU3kAZIA',
          //   avatarName: 'жизнь-в-опросах.jpg',
          // },
          // {
          //   name: 'Мир в опросах',
          //   link: 'https://tttttt.me/joinchat/AAAAAFQ4FzRJb-ab5LpBkw',
          //   avatarName: 'мир-в-опросах.jpg',
          // },
          /*{
            name: 'Пив&Ко NEWS',
            link: 'https://tttttt.me/joinchat/AAAAAFf57i-oDlV64yvfWg',
            avatarName: 'пивко-news.jpg',
          },*/
          /*{
            name: 'Brain Service | Прокачай мозги',
            link: 'https://tttttt.me/joinchat/AAAAAEu4PFlug7h0saKKRQ',
            avatarName: 'brain-service.jpg',
          },*/
          // {
          //   name: 'О ФИЛЬМАХ',
          //   link: 'https://tttttt.me/ofilms',
          //   avatarName: 'ofilms.jpg',
          // },
          // {
          //   name: '❄️Uncha Muncha',
          //   link: 'https://tttttt.me/muncha_uncha',
          //   avatarName: 'muncha_uncha.jpg',
          // },
          // {
          //   name: 'Футбольная мозгобойня',
          //   link: 'https://tttttt.me/footballmozgoboi',
          //   avatarName: 'footballmozgoboi.jpg',
          // },
        ]
      }
    },
    methods: {
      handleScroll() {
        this.updateStep()
        this.updateHeaderOpacity()
        this.updateActiveLink()
        this.updateOverviewBlockFixedPartPosition()
      },
      handleResize() {
        this.updateFeedbackWidth()
      },

      updateStep() {
        const children = Array.from(this.$refs.steps.children)
        if (window.innerWidth <= 600) {
          if (children[children.length - 1].getBoundingClientRect().bottom <= window.innerHeight - 200) {
            this.step = 100
            return
          }
          for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].getBoundingClientRect().top <= window.innerHeight - 75) {
              this.step = i + 1
              return
            }
          }
        } else {
          if (children[children.length - 1].getBoundingClientRect().bottom < window.innerHeight / 2) {
            this.step = 100
            return
          }
          for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].getBoundingClientRect().top < window.innerHeight / 2) {
              this.step = i + 1
              return
            }
          }
        }
        this.step = 0
      },
      updateHeaderOpacity() {
        const y = window.scrollY
        const h = window.innerHeight
        this.headerOpacity = Math.min(1, y / h)
      },
      setActiveLink(v) {
        if (this.activeLink !== v) {
          this.activeLink = v
        }
      },
      updateActiveLink() {
        const bodyRect = document.body.getBoundingClientRect()
        const getTop = (el) => {
          return el.getBoundingClientRect().top - bodyRect.top - 10
        }
        /*if (window.scrollY > getTop(this.$refs.contactsAnchor)) {
         this.activeLink = 'contacts'
         return
         }*/
        if (window.scrollY > getTop(this.$refs.pricingAnchor)) {
          this.setActiveLink('pricing')
          return
        }
        if (window.scrollY > getTop(this.$refs.feedbackAnchor)) {
          this.setActiveLink('feedback')
          return
        }
        if (window.scrollY > getTop(this.$refs.featuresAnchor)) {
          this.setActiveLink('features')
          return
        }
        if (window.scrollY > getTop(this.$refs.overviewAnchor)) {
          this.setActiveLink('overview')
          return
        }
        this.setActiveLink(null)
      },
      updateOverviewBlockFixedPartPosition() {
        this.overviewBlockFixedPartFixed = this.$refs.overviewBlockContent.getBoundingClientRect().top <= 58
      },

      getHighlightClass(i) {
        return {
          'overview-block__item_highlight': this.step === i,
          'overview-block__item_progress': this.step === i && [3, 4, 8].includes(this.step),
        }
      },

      px(v) {
        return `${v}px`
      },

      updateFeedbackWidth() {
        this.feedbackWidth = this.$refs.widthMeter && this.$refs.widthMeter.offsetWidth || 10
      },

      async goToFeedback(i) {
        this.enableFor('feedbackTransition', 500)
        await this.nextTick()
        this.feedbackIndex = i
      },

      scrollToTop() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      },
    },
    computed: {
      magnifiedClass() {
        // return [{'mockup_magnified': [1, 2, 3, 5, 6, 7].includes(this.step)}, `mockup_magnified_step${this.step}`]
      },

      price() {
        if (this.subscriptionLength === 12) {
          return 500
        }
        if (this.subscriptionLength === 6) {
          return 550
        }
        return 600
      },

      mockupVisible() {
        return this.step < 100
      },

      feedbackContainerStyle() {
        return {
          width: this.px(this.feedbackWidth * this.feedbackCount),
          left: this.px(-this.feedbackIndex * this.feedbackWidth)
        }
      },
      feedbackContainerClass() {
        return {
          'feedback-block__feedback-container_transition': this.feedbackTransition,
        }
      },
      feedbackStyle() {
        return {width: this.px(this.feedbackWidth)}
      },

      campaignName() {
        return this.$route && this.$route.query && this.$route.query.pk_campaign
      },

      promoCode() {
        if (!this.campaignName) {
          return false
        }
        if (this.campaignName === 'tgstat') {
          return 'TGSTAT'
        }
        return false
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, {
        capture: true,
        passive: true
      })
      window.addEventListener('resize', this.handleResize)

      this.handleScroll()
      this.handleResize()
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll, {
        capture: true,
        passive: true
      })
      window.removeEventListener('resize', this.handleResize)
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          async ({store}) => {
            if (store.state.auth.userIdFetched) {
              return {ok: true}
            }

            const result = await apiCheckAuth()
            if (result.ok) {
              const {user_id} = result.result
              await store.commit('auth/setUserId', user_id)
            }

            return {ok: true}
          },
        ]
      )
    },
  }
</script>

<style scoped lang="stylus">
  @import url('https://fonts.googleapis.com/css?family=Caveat:400&display=swap&subset=cyrillic')
  @import url('https://fonts.googleapis.com/css?family=Roboto:500&display=swap&subset=cyrillic')

  .landing
    @apply overflow-x-hidden bg-indigo-500 text-white

  .landing__block
    @apply flex flex-col items-center p-8

    @media (max-width 600px)
      @apply py-8 px-4

  .landing__block_full-height
    @apply h-screen justify-center

  .landing__logo
    width 200px
    @apply rounded-full
    background linear-gradient(-135deg, #d7ecff, #a2d0ff)

  .landing-header
    @apply fixed inset-x-0 w-full top-0 z-20
    @apply flex flex-row items-center
    height 58px
    padding 0 10px

  .landing-header__logo
    background-image url('~@/assets/logo/ChannelQuiz_logo@0,25x.png'), linear-gradient(-135deg, #d7ecff, #a2d0ff)
    background-size contain
    width 40px
    height 40px
    display inline-block
    @apply flex-shrink-0 mr-4 rounded-full

  .landing-header__link
    @apply px-4 py-2 text-white font-medium rounded-full border-2 border-transparent
    transition .2s

    &:hover
      @apply bg-indigo-600

  .landing-header__link_active
    @apply bg-indigo-600

  .landing-header__link_filled
    @apply border-solid border-2 border-white

  .landing-header__link-badge
    text-transform uppercase
    font-size .6rem
    padding 4px 6px
    vertical-align middle
    border-radius 999px
    font-weight 700
    @apply bg-white text-indigo-500

  .landing__block-header
    @apply text-center text-6xl font-bold
    font-family: 'Caveat', cursive;

    @media (max-width 400px)
      @apply text-5xl

  .landing__block-paragraph
    @apply text-xl

  .landing__anchor
    @apply relative
    top -70px

  .front-block__content
    @apply flex flex-row justify-center items-center

    @media (max-width 600px)
      @apply flex-col

  .front-block__text-content
    @apply flex flex-col justify-center

    @media (max-width 600px)
      @apply items-center

  .front-block__name
    @apply text-6xl font-bold

    @media (max-width 600px)
      @apply text-5xl

  .front-block__description
    @apply text-3xl
    font-family 'Caveat', cursive

    @media (max-width 600px)
      @apply text-2xl text-center

  //region overview-block
  .overview-block
    @apply px-0

  .overview-block__content
    @apply self-stretch flex flex-row relative

  .overview-block__items
    padding 35vh 0
    @apply flex-grow self-stretch flex flex-col items-stretch

    @media (max-width 600px)
      padding-top calc(100vh - 259px)

  .overview-block__item
    @apply relative
    min-height 40vh
    transition 500ms

    @media (max-width 600px)
      min-height 200px

  .overview-block__item-content
    @apply absolute z-10 inset-0
    @apply flex flex-col items-end justify-center text-right
    padding-right 50vw

    @media (max-width 600px)
      @apply pr-0 items-center text-center

  .overview-block__item-header
    @apply text-3xl

    @media (max-width 600px)
      @apply text-2xl

  .overview-block__item_highlight
    @apply bg-indigo-600

  .overview-block__item_progress:before
    content ''
    @apply absolute z-10 inset-y-0 left-0 bg-indigo-700
    animation overview-block__progress 10s linear infinite


  .overview-block__fixed-part
    @apply absolute z-10 top-0 right-0 self-start flex flex-col justify-center items-start
    height calc(100vh - 58px)
    width 50%

    @media (max-width 600px)
      @apply items-center
      height calc(100vh - 259px)
      width 100%
      @apply bg-indigo-500 shadow-md

  .overview-block__fixed-part_fixed
    @apply fixed
    top 58px

  .overview-block__fixed-part-content
    @apply relative w-full h-full flex flex-col justify-center items-center
    max-width calc(50vh - 29px)

    @media (max-width 600px)
      max-width calc(50vh - 130px)

  .overview-block__progress
    @apply absolute z-10 left-0 bottom-0
    height 5px
    background-color rgba(0, 0, 0, .5)
    animation overview-block__progress 10s linear infinite

  @keyframes overview-block__progress
    0%
      width 0
    98%, 100%
      width 100%
    0%, 98%
      opacity 1
    100%
      opacity 0

  .mockup__magnifier-wrapper
    @apply w-full relative
    grid-area a
    @apply flex flex-row items-center
    transition 1s

    &:before
      @apply inline-block
      content ''
      padding-top 200%

  .mockup__magnifier
    @apply w-full h-full overflow-hidden
    transition inherit

  .mockup_magnified
    .mockup__magnifier
      border-radius 50%
      height 50%
      background-color white
      @apply shadow-md

    &.mockup_magnified_step1
      .mockup
        transform scale(1.5) translate3d(14%, -16%, 0)

    &.mockup_magnified_step2
      .mockup
        transform translate3d(6%, 4%, 0)

    &.mockup_magnified_step3
      .mockup
        transform translate3d(6%, 4%, 0)

  .mockup
    @apply flex flex-row items-stretch absolute bottom-0 left-0 w-full
    transition inherit

    &:before
      @apply inline-block
      content ''
      padding-top 200%

    &:after
      content ''
      @apply absolute z-50 inset-0 bg-contain
      background-image url("~@/assets/landing/phone_mockup.png")

  .mockup__content
    @apply w-full relative overflow-hidden
    margin 15.2% 8.5% 9.6%
    background-color #d6e4ef

  .mockup__statusbar
    @apply absolute z-30 inset-x-0 w-full top-0

  .mockup__navbuttons
    @apply absolute z-30 inset-x-0 w-full bottom-0

  .mockup__top
    @apply absolute z-10 inset-x-0 w-full top-0

  .mockup__bottom
    @apply absolute z-10 inset-x-0 w-full bottom-0

  .mockup__post
    @apply absolute inset-x-0 w-full
    left .1%
    bottom 10%

  .mockup__pointer
    @apply absolute z-40 inline-block rounded-full
    width 4.15%
    height 2.075%
    opacity 0
    transform translate3d(-50%, -50%, 0)
    background-color rgba(255, 255, 255, .7)
    box-shadow 0 0 3px 0 rgb(131, 133, 149)

  @keyframes mockup__pointer_appear
    0%
      opacity 0
    100%
      opacity 1

  @keyframes mockup__pointer_unscale
    0%
      transform scale(1.2)
    100%
      transform scale(1)

  @keyframes mockup__pointer_hide
    0%
      opacity 1
    100%
      opacity 0

  @keyframes mockup__pointer_visible
    0%
      opacity 1
    100%
      opacity 1

  .mockup__popup
    @apply absolute inset-0 z-30 flex flex-row items-center justify-center
    background-color rgba(0, 0, 0, .5)
    padding 0 5%

  .mockup__step3-pointer
    animation step3-pointer 10s infinite

  @keyframes step3-pointer
    0%, 20%, 30%
      bottom 17.5%
      left 63%
    0%, 20%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    0%, 20%, 30%
      opacity 0
    22%, 28%
      opacity 1
    28%, 30%
      transform translate3d(-50%, 50%, 0)

    70%, 100%
      bottom 44%
      left 85%
    70%, 80%, 100%
      opacity 0
    70%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    72%, 78%
      opacity 1
    78%, 80%
      transform translate3d(-50%, 50%, 0)


  .mockup__step3-popup
    opacity 0
    animation step3-popup 10s infinite

  @keyframes step3-popup
    0%, 30%, 80%, 100%
      opacity 0
    32%, 78%
      opacity 1

  .mockup__step4-pointer
    animation step4-pointer 10s infinite

  @keyframes step4-pointer
    0%, 20%, 30%
      bottom 12.75%
      left 44%
    0%, 20%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    0%, 20%, 30%
      opacity 0
    22%, 28%
      opacity 1
    28%, 30%
      transform translate3d(-50%, 50%, 0)

    70%, 100%
      bottom 40.75%
      left 85%
    70%, 80%, 100%
      opacity 0
    70%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    72%, 78%
      opacity 1
    78%, 80%
      transform translate3d(-50%, 50%, 0)

  .mockup__step4-popup
    opacity 0
    animation step4-popup 10s infinite

  @keyframes step4-popup
    0%, 30%, 80%, 100%
      opacity 0
    32%, 78%
      opacity 1

  .mockup__webpage_header
    @apply absolute z-20 inset-x-0 w-full top-0

  .mockup__webpage
    @apply absolute inset-x-0 w-full z-10
    top 9.5%

  .mockup__step8-webpage-header
    animation step8-webpage-header 10s infinite

  @keyframes step8-webpage-header
    0%, 30%, 80%, 100%
      opacity 0
    32%, 78%
      opacity 1

  .mockup__step8-webpage
    animation step8-webpage 10s infinite

  @keyframes step8-webpage
    0%, 30%, 80%, 100%
      opacity 0
    32%, 78%
      opacity 1
    0%, 40%
      transform none
    65%, 100%
      transform translateY(-14%)

  .mockup__step8-pointer
    animation step8-pointer 10s infinite

  @keyframes step8-pointer
    0%, 20%, 30%
      bottom 12.75%
      left 44%
    0%, 20%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    0%, 20%, 30%
      opacity 0
    22%, 28%
      opacity 1
    28%, 30%
      transform translate3d(-50%, 50%, 0)

    40%, 65%
      left 85%
    40%
      bottom 25%
    65%
      bottom 39%
    40%, 65%
      opacity 0
    40%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    42%, 63%
      opacity 1
    42%, 65%
      transform translate3d(-50%, 50%, 0)

    70%, 80%
      bottom 2.5%
      left 71%
    70%
      transform translate3d(-50%, 50%, 0) scale(1.2)
    70%, 80%, 100%
      opacity 0
    72%, 78%
      opacity 1
    78%, 80%
      transform translate3d(-50%, 50%, 0)

  //endregion

  .features-block
    @apply -mx-8 bg-white text-gray-800 px-16 py-48
    padding-bottom 16rem
    transform skewY(-10deg)

  .features-block__content
    @apply w-full
    transform skewY(10deg)

  .features-block__features
    @apply flex flex-row flex-wrap mx-auto
    max-width 900px

  .features-block__feature
    @apply p-4 flex-grow flex flex-col items-start
    flex-basis 300px

    @media (max-width 400px)
      @apply items-center text-center

    img
      width 50px

  /*opacity .3*/
  //@apply -mb-8

  .features-block__feature-header
    @apply text-xl py-2 font-medium

  .feedback-block__channels
    @apply flex flex-row justify-center flex-wrap py-8
    max-width 800px

  .feedback-block__channel
    @apply flex flex-row items-center
    @apply rounded-full
    @apply border-2 border-dotted border-transparent
    padding 2px
    margin 5px

    &:hover
      @apply border-indigo-600

  .feedback-block__channel-image
    height 100px
    width 100px
    background-color lightcoral
    @apply rounded-full

    @media (max-width 600px)
      height 75px
      width 75px

  .feedback-block__channel-name
    padding 0 10px 0 5px

  .feedback-block__feedback-container-wrapper
    @apply flex flex-col items-stretch
    @apply border border-solid border-gray-200 rounded bg-white px-16 py-12 w-full text-gray-800 shadow-md
    max-width 750px

    @media (max-width 600px)
      @apply py-8 px-4

  .feedback-block__feedback-container
    @apply relative flex flex-row justify-center items-stretch flex-shrink-0 left-0

  .feedback-block__feedback-container_transition
    transition left .5s

  .feedback-block__feedback
    @apply flex-shrink-0 px-4 flex flex-col justify-between items-center

  .feedback-block__feedback-content
    @apply text-center font-medium pb-6 text-gray-800

    &:before
      content '«'

    &:after
      content '»'

  .feedback-block__feedback-avatar
    width 45px
    height 45px
    @apply rounded-full bg-indigo-300 mr-2

  .feedback-block__feedback-name
    @apply font-bold text-lg

  .feedback-block__feedback-description
    @apply font-medium text-sm text-gray-600

    a
      @apply underline
      color inherit

  .feedback-block__feedback-dots-wrapper
    @apply w-full flex flex-row justify-center pt-4

  .feedback-block__feedback-dots-dot
    @apply rounded-full p-1 m-1
    @apply bg-indigo-200

    &:hover
      @apply bg-indigo-300

  .feedback-block__feedback-dots-dot_active
    &, &:hover
      @apply bg-indigo-500

  .handwritten
    font-family Caveat, cursive

  .pricing-block
    @apply mb-12

  .pricing-block__content
    @apply flex flex-row items-stretch
    @apply border border-solid border-gray-200 bg-white text-gray-800 rounded shadow-md
    @media (max-width 800px)
      @apply flex-col

  .pricing-block__radio
    @apply flex flex-row flex-wrap

    @media (max-width 400px)
      @apply flex-col items-stretch text-center

  .pricing-block__benefits
    @apply flex flex-col items-stretch py-12 px-10 justify-center border-l border-solid
    @media (max-width 800px)
      @apply border-t border-l-0


  .pricing-block__switch-item
    @apply inline-block rounded-full

  .pricing-block__switch-item-content
    @apply inline-block rounded-full px-4 py-3 font-medium cursor-pointer w-full
    transition .2s

    &:hover
      @apply bg-indigo-100

  input:checked ~ .pricing-block__switch-item-content
    @apply bg-indigo-500 text-white

  .pricing-block__button
    @apply border-none rounded-full bg-indigo-500 mt-20 px-5 py-3 font-bold text-white text-2xl
    transition .2s

    &:hover
      @apply bg-indigo-600

  .contacts-block
    @apply border-t border-solid border-indigo-200 flex flex-col items-center pb-2

  .contacts-block__links
    @apply flex flex-row justify-center flex-wrap

  .contacts-block__link
    @apply text-white font-medium underline p-2
    transition .2s

    &:hover
      @apply text-indigo-100
</style>