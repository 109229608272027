<template>
  <a
    class="landing-header__link"
    :class="{'landing-header__link_active': activeLink === name}"
    :href="`#${name}`"
    v-smooth-scroll>
    <slot/>
  </a>
</template>

<script>
  export default {
    name: 'LandingHeaderLink',
    props: {
      name: String,
      activeLink: [String, Boolean],
    }
  }
</script>

<style scoped lang="stylus">

</style>